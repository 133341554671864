import "../styles/add-player-dialog.scss";
import { Champion, Team } from "../../types";
import { useState } from "react";
import Dialog from "../../ui-components/components/dialog";
import { useCopy } from "../../../contexts/copy-provider";

interface Props {
    availableChampions: Champion[];
    emptyTeams: Team[];
    onAddToTeam: (champions: Champion[], team: Team) => void;
    onOpenAddPlayerDialog: (team: Team | null) => void;
    onSetEmptyTeams: (teams: Team[]) => void;
    selectedTeam: Team;
}

const AddPlayersDialog = ({
    availableChampions,
    emptyTeams,
    onAddToTeam,
    onOpenAddPlayerDialog,
    onSetEmptyTeams,
    selectedTeam,
}: Props) => {
    const [selectedChampions, setSelectedChampions] = useState<Champion[]>([]);
    const [localEmptyTeams, setLocalEmptyTeams] = useState(emptyTeams);
    const { s } = useCopy();

    const handleSelectChampion = (champion: Champion) => {
        const newSelectedChampions = selectedChampions.includes(champion)
            ? selectedChampions.filter((c) => c !== champion)
            : [...selectedChampions, champion];

        const newEmptyTeams = localEmptyTeams.filter(
            (t) =>
                !(
                    t.champions.length +
                        (t.id === selectedTeam.id
                            ? newSelectedChampions.length
                            : 0) >
                    0
                ),
        );

        setSelectedChampions(newSelectedChampions);
        setLocalEmptyTeams(newEmptyTeams);
    };

    return (
        <Dialog
            hasGotItButton={false}
            onClose={() => {
                onOpenAddPlayerDialog(null);
            }}
        >
            <h2>
                {s("beer-pong.pages.team-fill-form.add-players-dialog.title")}
            </h2>
            <div className="tournament-form__add-player-dialog__list-group">
                {availableChampions.map((c, index) => (
                    <button
                        className={
                            "tournament-form__add-player-dialog__list-group__list-button " +
                            (selectedChampions.includes(c)
                                ? "tournament-form__add-player-dialog__list-group__list-button--select"
                                : "")
                        }
                        disabled={
                            availableChampions.length -
                                selectedChampions.length <=
                                localEmptyTeams.length &&
                            !selectedChampions.includes(c) &&
                            !localEmptyTeams.includes(selectedTeam)
                        }
                        key={index}
                        onClick={() => handleSelectChampion(c)}
                    >
                        {c.name}
                    </button>
                ))}
            </div>
            <div className="tournament-form__add-player-dialog__footer">
                <button
                    className="button-discard"
                    onClick={() => {
                        onOpenAddPlayerDialog(null);
                    }}
                >
                    {s("general.discard")}
                </button>
                <button
                    className="button-confirm"
                    onClick={() => {
                        onAddToTeam(selectedChampions, selectedTeam);
                        onSetEmptyTeams(localEmptyTeams);
                        onOpenAddPlayerDialog(null);
                    }}
                >
                    {s("general.confirm")}
                </button>
            </div>
        </Dialog>
    );
};

export default AddPlayersDialog;
