import "./styles/main.scss";
import { useCopy } from "../../contexts/copy-provider";
import React, { useState } from "react";
import Dialog from "../ui-components/components/dialog";
import PrivacyPolicy from "./components/privacy-policy";

interface Props {}

const Footer = ({}: Props) => {
    const [showPrivacyPolicyDialog, setShowPrivacyPolicyDialog] =
        useState(false);
    const { s } = useCopy();

    return (
        <>
            {showPrivacyPolicyDialog && (
                <Dialog onClose={() => setShowPrivacyPolicyDialog(false)}>
                    <PrivacyPolicy />
                </Dialog>
            )}
            <div className="footer">
                <p>© Tourney Champ 2024</p>
                <a onClick={() => setShowPrivacyPolicyDialog(true)}>
                    {s("privacy-policy.title")}
                </a>
            </div>
        </>
    );
};

export default Footer;
