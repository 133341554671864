import { TournamentSettings, TournamentType } from "../../types";
import "../styles/main.scss";
import { useCopy } from "../../../contexts/copy-provider";

interface Props {
    onSetTournamentType: (type: TournamentType) => void;
    tournamentSettings: TournamentSettings;
}

const TournamentTypeForm = ({
    onSetTournamentType,
    tournamentSettings,
}: Props) => {
    const { s } = useCopy();

    return (
        <div>
            <h2>{s("beer-pong.pages.tournament-type-form.title")}</h2>
            <div className="tournament-form__form-body">
                <span className="beta-tag">BETA</span>
                <button
                    className="tournament-form__group-button"
                    /*disabled={
                        !(
                            (tournamentSettings.playInTeams &&
                                tournamentSettings.teams.length &&
                                !(
                                    tournamentSettings.teams.length &
                                    (tournamentSettings.teams.length - 1)
                                )) ||
                            (!tournamentSettings.playInTeams &&
                                tournamentSettings.champions.length &&
                                !(
                                    tournamentSettings.champions.length &
                                    (tournamentSettings.champions.length - 1)
                                ))
                        )
                    }*/
                    onClick={() =>
                        onSetTournamentType(TournamentType.SINGLE_ELIMINATION)
                    }
                >
                    {s(
                        "beer-pong.pages.tournament-type-form.types.single-elimination",
                    )}
                </button>
                <button
                    className="tournament-form__group-button"
                    onClick={() =>
                        onSetTournamentType(TournamentType.ROUND_ROBIN)
                    }
                >
                    {s(
                        "beer-pong.pages.tournament-type-form.types.round-robin",
                    )}
                </button>
            </div>
        </div>
    );
};

export default TournamentTypeForm;
