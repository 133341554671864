import InputNumber from "../../ui-components/components/input-number";
import { useCopy } from "../../../contexts/copy-provider";

interface Props {
    teamsAmount: number;
    max?: number;
    min?: number;
    onChangeTeamsAmount: (amount: number) => void;
}

const TeamAmountForm = ({
    teamsAmount,
    max = 2,
    min = 2,
    onChangeTeamsAmount,
}: Props) => {
    const { s } = useCopy();

    return (
        <div>
            <h2>{s("beer-pong.pages.team-amount-form.title")}</h2>
            <div className="tournament-form__form-body">
                <InputNumber
                    onValueChange={onChangeTeamsAmount}
                    max={max}
                    min={min}
                    value={teamsAmount}
                />
            </div>
        </div>
    );
};

export default TeamAmountForm;
