import { TournamentSettings, TournamentType } from "../../types";
import { useCopy } from "../../../contexts/copy-provider";

interface Props {
    tournamentSettings: TournamentSettings;
}

const OverviewForm = ({ tournamentSettings }: Props) => {
    const { s } = useCopy();

    return (
        <div>
            <h2>{s("beer-pong.pages.overview-form.title")}</h2>
            <div className="tournament-form__form-body">
                <h4>{s("beer-pong.pages.overview-form.players")}:</h4>
                <h3 style={{ color: "red" }}>
                    {tournamentSettings.champions.length}
                </h3>
                {tournamentSettings.teams && (
                    <h4>{s("beer-pong.pages.overview-form.teams")}:</h4>
                )}
                <h3 style={{ color: "red" }}>
                    {tournamentSettings.playInTeams
                        ? tournamentSettings.teams.length
                        : "Nope"}
                </h3>
                <h4>{s("beer-pong.pages.overview-form.tournament-type")}:</h4>
                <h3 style={{ color: "red" }}>
                    {tournamentSettings.tournamentType ===
                    TournamentType.SINGLE_ELIMINATION
                        ? s(
                              "beer-pong.pages.tournament-type-form.types.single-elimination",
                          )
                        : s(
                              "beer-pong.pages.tournament-type-form.types.round-robin",
                          )}
                </h3>
            </div>
        </div>
    );
};

export default OverviewForm;
