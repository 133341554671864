import { Champion, Team, TeamCardInteraction } from "../types";
import { useEffect, useRef, useState } from "react";
import SpinWheelCanvas from "./components/spin-wheel-canvas";
import useDivSize from "../../hooks/useDivSize";
import TeamCard from "../team-card";
import "./styles/main.scss";
import { Simulate } from "react-dom/test-utils";
import copy = Simulate.copy;

interface Props {
    availableChampions: Champion[];
    onAddToTeam: (champion: Champion[], team: Team) => void;
    onChangeTeamName: (name: string, team: Team) => void;
    teams: Team[];
}

const SpinWheel = ({
    availableChampions,
    onAddToTeam,
    onChangeTeamName,
    teams,
}: Props) => {
    const [currentTeam, setCurrentTeam] = useState(() => {
        let lowestValue = Infinity;
        let nextTeam = teams[0];
        teams.forEach((t) => {
            if (t.champions.length < lowestValue) {
                lowestValue = t.champions.length;
                nextTeam = t;
            }
        });
        return nextTeam;
    });
    const [canSpin, setCanSpin] = useState(true);
    const wheelRef = useRef<HTMLDivElement>(null);
    const size = useDivSize(wheelRef);
    const [isReady, setIsReady] = useState(false);
    teams.sort((a, b) => b.champions.length - a.champions.length);

    useEffect(() => {
        if (size.width !== 0 && size.height !== 0) setIsReady(true);
    }, [size]);

    const getChampionFromSpin = (spinValue: number): Champion => {
        const normalized = 360 - (spinValue % 360);
        const fretSize = 360 / availableChampions.length;
        let current = 360;
        let index = availableChampions.length;
        while (current > normalized && index > 0) {
            index--;
            current -= fretSize;
        }
        return availableChampions[index];
    };

    const getRandomSpin = (min: number, max: number): number => {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    };
    const getSpinCorrection = (spinValue: number): number => {
        if (spinValue % (360 / availableChampions.length)) {
            return spinValue;
        }
        return spinValue + (Math.random() > 0.5 ? 2 : -2);
    };

    const setNextTeam = () => {
        let currentIndex = teams.findIndex((t) => t.id === currentTeam.id);
        let nextTeam = teams[++currentIndex % teams.length];
        nextTeam =
            teams.find((t) => {
                if (t.champions.length < nextTeam.champions.length) {
                    nextTeam = t;
                }
            }) || nextTeam;
        setCurrentTeam(nextTeam);
    };

    const spin = (): void => {
        const wheel = wheelRef.current;
        if (wheel) {
            setCanSpin(false);
            const currentSpinValue = parseInt(wheel.style.rotate) || 0;
            const newSpinValue =
                currentSpinValue + getRandomSpin(180, 10 * 360 - 180);
            const correctedSpinValue = getSpinCorrection(newSpinValue);
            const currentChampion = getChampionFromSpin(correctedSpinValue);

            wheel.style.transform = `rotate(${correctedSpinValue}deg`;
            setTimeout(() => {
                onAddToTeam([currentChampion], currentTeam);
                setNextTeam();
                setCanSpin(true);
            }, 5000);
        }
    };

    return (
        <div className="tournament-form__form-body">
            <div className="spin-wheel__wheel">
                <div className="spin-wheel__wheel__plate" ref={wheelRef}>
                    {isReady && (
                        <SpinWheelCanvas
                            champions={availableChampions}
                            diameter={size.width}
                        />
                    )}
                </div>
                <button
                    className="spin-wheel__wheel__spin-button"
                    disabled={!(canSpin && availableChampions.length > 0)}
                    onClick={spin}
                >
                    Spin!
                </button>
                <div className="spin-wheel__wheel__wheel-head"></div>
            </div>
            <div className="spin-wheel__teams">
                {teams.map((t, index) => (
                    <TeamCard
                        active={
                            t.id === currentTeam.id ||
                            !(availableChampions.length > 0)
                        }
                        interactions={[TeamCardInteraction.CHANGE_NAME]}
                        key={index}
                        onChangeTeamName={onChangeTeamName}
                        team={t}
                    />
                ))}
            </div>
        </div>
    );
};

export default SpinWheel;
