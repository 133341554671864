import React, { useState } from "react";
import PlayerAmountForm from "./components/player-amount-form";
import PlayerNamesForm from "./components/player-names-form";
import PlayInTeamsForm from "./components/play-in-teams-form";
import TeamAmountForm from "./components/team-amount-form";
import TeamFillForm from "./components/team-fill-form";
import {
    TournamentType,
    Champion,
    Team,
    Page,
    TournamentSettings,
} from "../types";
import SetTournamentType from "./components/tournament-type-form";
import OverviewForm from "./components/overview-form";
import "./styles/main.scss";
import Dialog from "../ui-components/components/dialog";
import { useCopy } from "../../contexts/copy-provider";
import { useLocation, useNavigate } from "react-router-dom";
import sessionsApi from "../../services/sessions-api";
import { Adsense } from "@ctrl/react-adsense";
import { v4 as uuidv4 } from "uuid";

interface Props {
    availableChampions: Champion[];
    onSetAvailableChampions: (availableChampions: Champion[]) => void;
    onSetPage: (page: Page) => void;
    onSetTournamentSettings: (tournamentSettings: TournamentSettings) => void;
    page: Page;
    tournamentSettings: TournamentSettings;
}

const calculateMaxTeamsAmount = (playersAmount: number): number => {
    return playersAmount > 2 ? Math.ceil(playersAmount / 2) : 2;
};

const TournamentForm = ({
    availableChampions,
    onSetAvailableChampions,
    onSetPage,
    onSetTournamentSettings,
    page,
    tournamentSettings,
}: Props) => {
    const [showPlayersAvailableWarning, setShowPlayersAvailableWarning] =
        useState(false);
    const [maxTeamsAmount, setMaxTeamsAmount] = useState(
        calculateMaxTeamsAmount(tournamentSettings.champions.length),
    );
    const navigate = useNavigate();
    const location = useLocation();
    const { s } = useCopy();
    const [showWelcomeDialog, setShowWelcomeDialog] = useState(
        !location?.state?.persisted,
    );

    const handleClearTeams = () => {
        const newAvailableChampions = [...availableChampions];
        const teams = tournamentSettings.teams;
        teams.forEach((t) => {
            t.champions.forEach((c) => newAvailableChampions.push(c));
            t.champions = [];
        });
        onSetAvailableChampions(newAvailableChampions);
        onSetTournamentSettings({ ...tournamentSettings, teams });
    };

    const handleDistributePlayers = () => {
        const teams = tournamentSettings.teams;
        let startIndex = 0;
        let nextTeam = teams[startIndex];
        availableChampions.forEach((c) => {
            nextTeam =
                teams.find((t) => {
                    if (t.champions.length < nextTeam.champions.length) {
                        nextTeam = t;
                    }
                }) || nextTeam;
            if (!nextTeam.champions.find((c2) => c.id === c2.id)) {
                nextTeam.champions.push(c);
            }
            nextTeam = teams[++startIndex % teams.length];
        });
        onSetTournamentSettings({ ...tournamentSettings, teams });
        onSetAvailableChampions([]);
    };

    const handleAddToTeam = (champions: Champion[], team: Team) => {
        let newAvailableChampions = availableChampions;
        champions.forEach((champion) => {
            if (!team.champions.find((c) => c.id === champion.id)) {
                team.champions.push(champion);
                newAvailableChampions = newAvailableChampions.filter(
                    (c) => c.id !== champion.id,
                );
            }
        });
        onSetAvailableChampions(newAvailableChampions);
        const teams = tournamentSettings.teams.map((t) => {
            return t.id === team.id ? team : t;
        });
        onSetTournamentSettings({ ...tournamentSettings, teams });
    };

    const handleRemoveFromTeam = (champion: Champion, team: Team) => {
        team.champions = team.champions.filter((c) => c.id !== champion.id);
        onSetAvailableChampions([
            ...availableChampions.filter((c) => c.id !== champion.id),
            champion,
        ]);
        const teams = tournamentSettings.teams.map((t) => {
            return t.id === team.id ? team : t;
        });
        onSetTournamentSettings({ ...tournamentSettings, teams });
    };

    const handleChangeChampionsAmount = (amount: number) => {
        const champions = tournamentSettings.champions;
        let newAvailableChampions = availableChampions;
        let teams = tournamentSettings.teams;

        if (amount === champions.length) return;
        while (amount > champions.length) {
            const newChampion = {
                id: champions.length,
                name: `Player ${champions.length + 1}`,
            };
            champions.push(newChampion);
            newAvailableChampions.push(newChampion);
        }
        while (amount < champions.length) {
            const last = champions.pop();
            if (last) {
                newAvailableChampions = newAvailableChampions.filter(
                    (c) => c.id !== last.id,
                );
                teams = tournamentSettings.teams.map((t) => {
                    return {
                        ...t,
                        champions: t.champions.filter((c) => c.id !== last.id),
                    };
                });
            }
        }

        onSetTournamentSettings({
            ...tournamentSettings,
            champions: champions,
            teams: teams,
        });

        if (maxTeamsAmount < teams.length) {
            handleChangeTeamsAmount(maxTeamsAmount);
        }

        onSetAvailableChampions(newAvailableChampions);
        setMaxTeamsAmount(calculateMaxTeamsAmount(champions.length));
    };

    const handleChangeChampionName = (id: number, newName: string) => {
        const champions = tournamentSettings.champions;
        champions[id].name = newName;
        onSetTournamentSettings({
            ...tournamentSettings,
            champions: champions,
        });
    };

    const handleChangeTeamName = (name: string, team: Team) => {
        team.name = name;
        onSetTournamentSettings({
            ...tournamentSettings,
            teams: tournamentSettings.teams.map((t) =>
                t.id === team.id ? team : t,
            ),
        });
    };

    const handleChangeTeamsAmount = (amount: number) => {
        const teams = tournamentSettings.teams;
        if (amount === teams.length) {
            return;
        }
        while (amount > tournamentSettings.teams.length) {
            teams.push({
                id: teams.length,
                name: `Team ${String.fromCharCode(teams.length + 1 + 64)}`,
                champions: [],
            });
        }

        const emptyTeamsAmount = teams.reduce((count, t) => {
            if (t.champions.length > 0) {
                return count;
            }
            return count + 1;
        }, 0);

        if (emptyTeamsAmount > availableChampions.length) {
            handleClearTeams();
        }

        while (amount < tournamentSettings.teams.length) {
            const last = teams.pop();
            if (last?.champions) {
                const newAvailableChampions = availableChampions.concat(
                    last.champions,
                );
                onSetAvailableChampions(newAvailableChampions);
            }
        }

        onSetTournamentSettings({ ...tournamentSettings, teams: teams });
    };

    const handleClickBack = (value: number) => {
        if (page === Page.PLAYER_AMOUNT_FORM) return;
        onSetPage(page - value);
    };

    const handleClickNext = (value: number) => {
        if (page >= 7 - 1) return;
        onSetPage(page + value);
    };

    const handleSetPlayInTeams = (value: boolean) => {
        onSetTournamentSettings({ ...tournamentSettings, playInTeams: value });
    };

    const handleSetTournamentType = (type: TournamentType) => {
        onSetTournamentSettings({
            ...tournamentSettings,
            tournamentType: type,
        });
        handleClickNext(1);
    };

    const handleStartTournament = (distributePlayers: boolean) => {
        if (
            tournamentSettings.playInTeams &&
            availableChampions.length > 0 &&
            !distributePlayers
        ) {
            setShowPlayersAvailableWarning(true);
            return;
        }
        if (distributePlayers) {
            handleDistributePlayers();
        }

        const req = {
            sessionId: uuidv4(),
            players: tournamentSettings.champions.length,
            teams:
                (tournamentSettings.playInTeams &&
                    tournamentSettings.teams.length) ||
                0,
            type: tournamentSettings.tournamentType,
        };

        sessionsApi.post("/tournament_sessions", req).catch(() => {});

        onSetPage(Page.TOURNAMENT);
        navigate("/tournament", {
            state: {
                availableChampions: [],
                persisted: true,
                sessionId: req.sessionId,
                tournamentSettings: tournamentSettings,
            },
        });
    };

    return (
        <div className="tournament-form">
            {showWelcomeDialog && (
                <Dialog onClose={() => setShowWelcomeDialog(false)}>
                    <h1>{s("beer-pong.pages.welcome-dialog.title")}</h1>
                    {Object.entries(
                        s(`beer-pong.pages.welcome-dialog.content`),
                    ).map(([key, value], index) => {
                        const style = key.split("-")[key.split("-").length - 1];
                        switch (style) {
                            case "header":
                                return (
                                    <h2
                                        className="help-dialog-text"
                                        key={index}
                                    >
                                        {value as string}
                                    </h2>
                                );
                            case "subheader":
                                return (
                                    <h3
                                        className="help-dialog-text"
                                        key={index}
                                    >
                                        {value as string}
                                    </h3>
                                );
                            case "subsubheader":
                                return (
                                    <h5
                                        className="help-dialog-text"
                                        key={index}
                                    >
                                        {value as string}
                                    </h5>
                                );
                            default:
                                return (
                                    <p
                                        key={index}
                                        className="help-dialog-text help-dialog-text__p"
                                    >
                                        {value as string}
                                    </p>
                                );
                        }
                    })}
                </Dialog>
            )}
            <h1>{s("beer-pong.title")}</h1>
            {page === Page.PLAYER_AMOUNT_FORM && (
                <PlayerAmountForm
                    amount={tournamentSettings.champions.length}
                    onValueChange={handleChangeChampionsAmount}
                />
            )}
            {page === Page.PLAYER_NAMES_FORM && (
                <PlayerNamesForm
                    champions={tournamentSettings.champions}
                    onChangeChampionName={handleChangeChampionName}
                />
            )}
            {page === Page.PLAY_IN_TEAMS_FORM && (
                <PlayInTeamsForm
                    onClickNext={handleClickNext}
                    setPlayInTeams={handleSetPlayInTeams}
                />
            )}
            {page === Page.TEAM_AMOUNT_FORM && (
                <TeamAmountForm
                    teamsAmount={tournamentSettings.teams.length}
                    max={maxTeamsAmount}
                    onChangeTeamsAmount={handleChangeTeamsAmount}
                />
            )}
            {page === Page.TEAM_FILL_FORM && (
                <TeamFillForm
                    availableChampions={availableChampions}
                    onAddToTeam={handleAddToTeam}
                    onChangeTeamName={handleChangeTeamName}
                    onClearTeams={handleClearTeams}
                    onRemoveFromTeam={handleRemoveFromTeam}
                    teams={tournamentSettings.teams}
                />
            )}
            {page === Page.TOURNAMENT_TYPE_FORM && (
                <SetTournamentType
                    onSetTournamentType={handleSetTournamentType}
                    tournamentSettings={tournamentSettings}
                />
            )}
            {page === Page.OVERVIEW_FORM && (
                <OverviewForm tournamentSettings={tournamentSettings} />
            )}
            {showPlayersAvailableWarning && (
                <Dialog
                    hasGotItButton={false}
                    onClose={() => setShowPlayersAvailableWarning(false)}
                >
                    <div className="tournament-form__dialog">
                        <h2>
                            {s(
                                "beer-pong.pages.available-players-warning.title",
                            )}
                        </h2>
                        <h4>
                            {s(
                                "beer-pong.pages.available-players-warning.copy",
                            )}
                        </h4>
                        <div>
                            <button
                                className="button-discard"
                                onClick={() =>
                                    setShowPlayersAvailableWarning(false)
                                }
                            >
                                {s("general.go-back")}
                            </button>
                            <button
                                className="button-confirm"
                                onClick={() => handleStartTournament(true)}
                            >
                                {s("general.start-anyway")}
                            </button>
                        </div>
                    </div>
                </Dialog>
            )}
            <div>
                {page !== Page.PLAYER_AMOUNT_FORM && (
                    <button
                        onClick={() => {
                            if (
                                page > Page.PLAY_IN_TEAMS_FORM &&
                                !tournamentSettings.playInTeams &&
                                page <= Page.OVERVIEW_FORM
                            ) {
                                return handleClickBack(
                                    page - Page.PLAY_IN_TEAMS_FORM,
                                );
                            }
                            handleClickBack(1);
                        }}
                        type="button"
                    >
                        Back
                    </button>
                )}

                {page < 6 - 1 &&
                    page !== Page.PLAY_IN_TEAMS_FORM &&
                    page !== Page.TOURNAMENT_TYPE_FORM && (
                        <button
                            onClick={() => handleClickNext(1)}
                            type="button"
                        >
                            Next
                        </button>
                    )}
                {page === Page.OVERVIEW_FORM && (
                    <button
                        className=""
                        onClick={() => handleStartTournament(false)}
                    >
                        Start
                    </button>
                )}
            </div>
            <Adsense client="ca-pub-8619869984033703" slot="8474781300" />
        </div>
    );
};

export default TournamentForm;
