import { Champion, Team, TeamCardInteraction } from "../types";
import "./styles/main.scss";

interface Props {
    active?: boolean;
    interactions?: TeamCardInteraction[];
    onChangeTeamName?: (name: string, team: Team) => void;
    onOpenAddPlayerDialog?: (team: Team | null) => void;
    onRemoveFromTeam?: (champion: Champion, team: Team) => void;
    team: Team;
}

const TeamCard = ({
    active = true,
    interactions = [],
    onChangeTeamName = (name: string, team: Team) => {},
    onOpenAddPlayerDialog = (team: Team | null) => {},
    onRemoveFromTeam = (champion: Champion, team: Team) => {},
    team,
}: Props) => {
    return (
        <div className="team-card">
            <input
                className={
                    "input-title" + (!active ? " input-title__inactive" : "")
                }
                defaultValue={team.name}
                disabled={
                    !interactions?.includes(TeamCardInteraction.CHANGE_NAME)
                }
                maxLength={16}
                minLength={1}
                onBlur={(e) => onChangeTeamName(e.target.value, team)}
                onFocus={(e) => e.target.select()}
                type="text"
            />
            <div className="team-card__champions">
                {team.champions.map((c, index) => (
                    <div className="team-card__champions__champion" key={index}>
                        <button
                            className="team-card__champions__champion__remove-button"
                            disabled={true}
                        >
                            -
                        </button>
                        {c.name}

                        <button
                            className="team-card__champions__champion__remove-button"
                            disabled={
                                !interactions?.includes(
                                    TeamCardInteraction.REMOVE_CHAMPIONS,
                                )
                            }
                            onClick={() => onRemoveFromTeam(c, team)}
                        >
                            -
                        </button>
                    </div>
                ))}
            </div>
            {interactions?.includes(TeamCardInteraction.ADD_CHAMPIONS) && (
                <button
                    className="team-card__add-button"
                    onClick={() => onOpenAddPlayerDialog(team)}
                >
                    +
                </button>
            )}
        </div>
    );
};

export default TeamCard;
