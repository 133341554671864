import React, { ReactNode, useEffect, useRef } from "react";
import "../styles/dialog.scss";
import { useCopy } from "../../../contexts/copy-provider";

interface Props {
    children?: ReactNode;
    hasCloseButton?: boolean;
    hasGotItButton?: boolean;
    onClose: () => void;
}

const Dialog = ({
    children,
    hasCloseButton = true,
    hasGotItButton = true,
    onClose,
}: Props) => {
    const background = useRef<null | HTMLDivElement>(null);
    const { s } = useCopy();

    const setBackgroundHeight = () => {
        background.current?.style.setProperty(
            "--full-height",
            `${document.body.scrollHeight}px`,
        );
    };
    window.addEventListener("resize", setBackgroundHeight);

    useEffect(() => {
        setBackgroundHeight();
        background.current?.scrollIntoView();
    }, [background]);

    return (
        <>
            <div
                ref={background}
                className="dialog-background"
                onClick={onClose}
            />
            <div className="dialog">
                <div className="dialog__window">
                    {hasCloseButton && (
                        <button
                            className="dialog__window__close-button"
                            onClick={onClose}
                        >
                            x
                        </button>
                    )}
                    <div className="dialog__window__dialog-body">
                        {children}
                        {hasGotItButton && (
                            <button
                                className="dialog__window__got-it-button"
                                onClick={onClose}
                            >
                                {s("general.got-it")}
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Dialog;
