import Dialog from "../ui-components/components/dialog";
import React, { useState } from "react";
import { useCopy } from "../../contexts/copy-provider";
import { Page } from "../types";
import { useNavigate } from "react-router-dom";

interface Props {
    onSetPage: (page: Page) => void;
    page: Page;
}

const Header = ({ onSetPage, page }: Props) => {
    const [showHelpDialog, setShowHelpDialog] = useState(false);
    const [showQuitDialog, setShowQuitDialog] = useState(false);
    const navigate = useNavigate();
    const { s } = useCopy();

    const handleQuit = () => {
        setShowQuitDialog(false);
        onSetPage(Page.PLAYER_AMOUNT_FORM);
        navigate("/", { state: { matches: [], persisted: true } });
    };

    const getPageEnumText = (page: Page): string => {
        switch (page) {
            case Page.PLAYER_AMOUNT_FORM:
                return "player-amount-form";
            case Page.PLAYER_NAMES_FORM:
                return "player-names-form";
            case Page.PLAY_IN_TEAMS_FORM:
                return "play-in-teams-form";
            case Page.TEAM_AMOUNT_FORM:
                return "team-amount-form";
            case Page.TEAM_FILL_FORM:
                return "team-fill-form";
            case Page.TOURNAMENT_TYPE_FORM:
                return "tournament-type-form";
            case Page.OVERVIEW_FORM:
                return "overview-form";
            case Page.TOURNAMENT:
                return "tournament";
            default:
                return "";
        }
    };

    return (
        <>
            {page === Page.TOURNAMENT && (
                <span
                    className="material-symbols-outlined material-symbols-outlined__header material-symbols-outlined__header__return"
                    onClick={() => {
                        setShowQuitDialog(true);
                    }}
                >
                    replay
                </span>
            )}
            <span
                className="material-symbols-outlined material-symbols-outlined__header material-symbols-outlined__header__help"
                onClick={() => setShowHelpDialog(true)}
            >
                help
            </span>
            {showQuitDialog && (
                <Dialog
                    hasGotItButton={false}
                    onClose={() => setShowQuitDialog(false)}
                >
                    <h2>{s("beer-pong.pages.quit-dialog.title")}</h2>
                    <h4>{s("beer-pong.pages.quit-dialog.copy")}</h4>
                    <div>
                        <button
                            className="button-discard"
                            onClick={() => {
                                setShowQuitDialog(false);
                            }}
                        >
                            {s("general.no")}
                        </button>
                        <button className="button-confirm" onClick={handleQuit}>
                            {s("general.yes")}
                        </button>
                    </div>
                </Dialog>
            )}
            {showHelpDialog && (
                <Dialog onClose={() => setShowHelpDialog(false)}>
                    <h2>
                        {s(
                            `beer-pong.pages.${getPageEnumText(
                                page,
                            )}.help-dialog.title`,
                        )}
                    </h2>
                    {Object.entries(
                        s(
                            `beer-pong.pages.${getPageEnumText(
                                page,
                            )}.help-dialog.content`,
                        ),
                    ).map(([key, value], index) =>
                        key.includes("-header") ? (
                            <h5 className="help-dialog-text" key={index}>
                                {value as string}
                            </h5>
                        ) : (
                            <p
                                key={index}
                                className="help-dialog-text help-dialog-text__p"
                            >
                                {value as string}
                            </p>
                        ),
                    )}
                </Dialog>
            )}
        </>
    );
};

export default Header;
