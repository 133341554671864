import { RefObject, useEffect, useState } from "react";

const useDivSize = (ref: RefObject<HTMLDivElement>) => {
    const [size, setSize] = useState({
        height: ref.current?.offsetHeight,
        width: ref.current?.offsetWidth,
    });

    useEffect(() => {
        const area = ref.current;

        if (!area) return;

        const handleResize = () => {
            setSize({
                height: area.clientHeight || 500,
                width: area.clientWidth || 500,
            });
        };

        handleResize();

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [ref]);

    return size;
};

export default useDivSize;
