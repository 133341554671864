import { Match, Player, TournamentType } from "../../types";
import { useEffect, useRef, useState } from "react";
import MatchCard from "./match-card";
import { useLocation } from "react-router-dom";

interface Props {
    matches: Match[];
    onSetMatches: (matches: Match[]) => void;
    onSetMatchWinner: (match: Match, winner: Player, canTie: boolean) => void;
    onSetTournamentWinner: (winner: Player[]) => void;
    playerCount: number;
    players: Player[];
}

const SingleElimination = ({
    matches,
    onSetMatches,
    onSetMatchWinner,
    onSetTournamentWinner,
    playerCount,
    players,
}: Props) => {
    const [rounds, setRounds] = useState<Match[][]>([]);
    const location = useLocation();

    const handleSetMatchWinner = (
        match: Match,
        winner: Player,
        canTie: boolean = false,
    ) => {
        onSetMatchWinner(match, winner, canTie);
        const hasWon = match.winner.some((w) => w.id === winner.id);
        let hasAdvanced = false;
        const changedMatches: Match[] = [];
        for (let i = 0; i < rounds.length; i++) {
            const roundMatches = rounds[i];
            for (let j = 0; j < roundMatches.length; j++) {
                const roundMatch = roundMatches[j];
                if (roundMatch.id < match.id) {
                    continue;
                }
                if (roundMatch.id === match.id) {
                    break;
                }
                if (hasWon) {
                    const loser = match.players.find((l) => l.id !== winner.id);
                    if (
                        loser &&
                        roundMatch.players.some((p) => p.id === loser.id)
                    ) {
                        roundMatch.players = roundMatch.players.filter(
                            (p) => p.id !== loser.id,
                        );
                        roundMatch.winner.forEach((w) =>
                            handleSetMatchWinner(roundMatch, w, canTie),
                        );
                    }
                    if (!hasAdvanced && roundMatch.players.length < 2) {
                        roundMatch.players.push(winner);
                        hasAdvanced = true;
                    }
                    changedMatches.push(roundMatch);
                }
                if (
                    !hasWon &&
                    roundMatch.players.some((p) => p.id === winner.id)
                ) {
                    roundMatch.players = roundMatch.players.filter(
                        (p) => p.id !== winner.id,
                    );
                    roundMatch.winner.forEach((w) =>
                        handleSetMatchWinner(roundMatch, w, canTie),
                    );
                    changedMatches.push(roundMatch);
                    break;
                }
            }
        }
        const newMatches = matches.map(
            (m) => changedMatches.find((cM) => cM.id === m.id) || m,
        );

        const lastWinner = newMatches[newMatches.length - 1].winner;
        if (lastWinner.length > 0 && lastWinner[0].id === winner.id) {
            onSetTournamentWinner(newMatches[newMatches.length - 1].winner);
        } else {
            onSetTournamentWinner([]);
        }

        onSetMatches(newMatches);
    };

    useEffect(() => {
        const calculateByeAmount = (players: number) => {
            const x = Math.log2(players);
            if (x % 1 === 0) {
                return 0;
            }
            return Math.pow(2, Math.ceil(x)) - players;
        };

        const generateMatches = () => {
            const matches: Match[] = [];

            const halfIndex = playerCount / 2;
            const firstHalf = players.slice(0, halfIndex);
            const secondHalf = players
                .slice(halfIndex, players.length)
                .reverse();

            for (let i = 0; i < playerCount - 1; i++) {
                const players: Player[] = [];
                if (i < firstHalf.length) {
                    players.push(firstHalf[i]);
                }
                if (i < secondHalf.length) {
                    players.push(secondHalf[i]);
                }
                matches.push({
                    id: i,
                    players: players,
                    winner: [],
                });
            }

            onSetMatches(matches);
        };

        const byes = calculateByeAmount(playerCount);
        if (!(matches.length > 0)) {
            if (!(location.state?.matches?.length > 0)) {
                generateMatches();
            } else {
                onSetMatches(location.state.matches);
            }
        }

        let roundLength = (playerCount - byes) / 2;
        const rounds: Match[][] = [];
        let currentRound: Match[] = [];

        matches.forEach((m, index) => {
            currentRound.push(m);
            if (currentRound.length >= roundLength) {
                rounds.push([...currentRound]);
                currentRound = [];
                roundLength =
                    byes > 0 && rounds.length === 1
                        ? Math.ceil((roundLength + byes) / 2)
                        : Math.ceil(roundLength / 2);
            }
        });

        setRounds(rounds);
    }, [matches, playerCount, players, onSetMatches, location.state.matches]);

    return (
        <div className="tournament__matches">
            {rounds.map((r, index) => (
                <div className="tournament__matches__round" key={index}>
                    <h4>Round {index + 1}</h4>
                    {r.map((m, index) => (
                        <MatchCard
                            canTie={false}
                            interactable={m.players.length > 1}
                            key={index}
                            match={m}
                            onSetWinner={handleSetMatchWinner}
                        />
                    ))}
                </div>
            ))}
        </div>
    );
};

export default SingleElimination;
