import { Champion } from "../../types";
import { useCopy } from "../../../contexts/copy-provider";

interface Props {
    champions: Champion[];
    onChangeChampionName: (index: number, name: string) => void;
}

const PlayerNamesForm = ({ champions, onChangeChampionName }: Props) => {
    const { s } = useCopy();

    return (
        <div>
            <h2>{s("beer-pong.pages.player-names-form.title")}</h2>
            <div className="tournament-form__form-body">
                {champions.map((c) => (
                    <input
                        defaultValue={c.name}
                        key={c.id}
                        minLength={1}
                        maxLength={16}
                        onBlur={(event) =>
                            onChangeChampionName(c.id, event.target.value)
                        }
                        onFocus={(e) => e.target.select()}
                        type="text"
                    />
                ))}
            </div>
        </div>
    );
};

export default PlayerNamesForm;
