import ManualTeamForm from "./manual-team-form";
import { Champion, Team } from "../../types";
import SpinWheel from "../../spin-wheel";
import React, { useState } from "react";
import "../styles/team-fill-form.scss";
import { useCopy } from "../../../contexts/copy-provider";
import Dialog from "../../ui-components/components/dialog";

interface Props {
    availableChampions: Champion[];
    onAddToTeam: (champions: Champion[], team: Team) => void;
    onChangeTeamName: (name: string, team: Team) => void;
    onClearTeams: () => void;
    onRemoveFromTeam: (champion: Champion, team: Team) => void;
    teams: Team[];
}

enum TeamFill {
    Manual,
    SpinWheel,
}

const TeamFillForm = ({
    availableChampions,
    onAddToTeam,
    onChangeTeamName,
    onClearTeams,
    onRemoveFromTeam,
    teams,
}: Props) => {
    const [teamFill, setTeamFill] = useState(TeamFill.Manual);
    const { s } = useCopy();
    const [showClearDialog, setShowClearDialog] = useState(false);

    return (
        <div>
            <h2>{s("beer-pong.pages.team-fill-form.title")}</h2>
            <div className="tournament-form__form-body">
                <div className="tournament-form__team-fill-form__tab-group">
                    <button
                        className={
                            "tournament-form__team-fill-form__tab-group__tab-button " +
                            (teamFill !== TeamFill.Manual
                                ? "tournament-form__team-fill-form__tab-group__tab-button--deselect"
                                : "")
                        }
                        onClick={() => setTeamFill(TeamFill.Manual)}
                    >
                        {s("beer-pong.pages.team-fill-form.manual.title")}
                    </button>
                    <button
                        className={
                            "tournament-form__team-fill-form__tab-group__tab-button " +
                            (teamFill !== TeamFill.SpinWheel
                                ? "tournament-form__team-fill-form__tab-group__tab-button--deselect"
                                : "")
                        }
                        onClick={() => setTeamFill(TeamFill.SpinWheel)}
                    >
                        {s(
                            "beer-pong.pages.team-fill-form.spin-the-wheel.title",
                        )}
                    </button>
                    <button
                        className={
                            "tournament-form__team-fill-form__tab-group__tab-button tournament-form__team-fill-form__tab-group__tab-button--deselect tournament-form__team-fill-form__tab-group__tab-button--warning"
                        }
                        disabled={!teams.some((t) => t.champions.length > 0)}
                        onClick={() => setShowClearDialog(true)}
                    >
                        <span className="material-symbols-outlined material-symbols-outlined__clear">
                            replay
                        </span>
                    </button>
                </div>
                {showClearDialog && (
                    <Dialog
                        hasGotItButton={false}
                        onClose={() => setShowClearDialog(false)}
                    >
                        <h2>
                            {s(
                                "beer-pong.pages.team-fill-form.clear-teams-dialog.title",
                            )}
                        </h2>
                        <h4>
                            {s(
                                "beer-pong.pages.team-fill-form.clear-teams-dialog.copy",
                            )}
                        </h4>
                        <div>
                            <button
                                className="button-discard"
                                onClick={() => {
                                    setShowClearDialog(false);
                                }}
                            >
                                {s("general.no")}
                            </button>
                            <button
                                className="button-confirm"
                                onClick={() => {
                                    onClearTeams();
                                    setShowClearDialog(false);
                                }}
                            >
                                {s("general.yes")}
                            </button>
                        </div>
                    </Dialog>
                )}
                {teamFill === TeamFill.Manual && (
                    <ManualTeamForm
                        availableChampions={availableChampions}
                        onAddToTeam={onAddToTeam}
                        onChangeTeamName={onChangeTeamName}
                        onRemoveFromTeam={onRemoveFromTeam}
                        teams={teams}
                    />
                )}
                {teamFill === TeamFill.SpinWheel && (
                    <SpinWheel
                        availableChampions={availableChampions}
                        onAddToTeam={onAddToTeam}
                        onChangeTeamName={onChangeTeamName}
                        teams={teams}
                    />
                )}
            </div>
        </div>
    );
};

export default TeamFillForm;
