import React, { useEffect, useState } from "react";
import "./styles/main.scss";
import { CopyProvider } from "./contexts/copy-provider";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Header from "./components/header";
import {
    Champion,
    Page,
    TournamentSettings,
    TournamentType,
} from "./components/types";
import TournamentForm from "./components/tournament-form";
import Tournament from "./components/tournament";
import Footer from "./components/footer";
import PrivacyPolicy from "./components/footer/components/privacy-policy";

const App = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [page, setPage] = useState(Page.PLAYER_AMOUNT_FORM);
    const [tournamentSettings, setTournamentSettings] =
        useState<TournamentSettings>(
            (location?.state?.tournamentSettings as TournamentSettings) || {
                champions: [
                    { id: 0, name: "Player 1" },
                    { id: 1, name: "Player 2" },
                    { id: 2, name: "Player 3" },
                    { id: 3, name: "Player 4" },
                ],
                playInTeams: false,
                teams: [
                    { id: 0, champions: [], name: "Team A" },
                    { id: 1, champions: [], name: "Team B" },
                ],
                tournamentType: TournamentType.ROUND_ROBIN,
            },
        );
    const [availableChampions, setAvailableChampions] = useState<Champion[]>(
        location?.state?.availableChampions ||
            tournamentSettings.champions.map((c) => c),
    );

    useEffect(() => {
        if (location?.pathname !== "/privacy" && !location?.state?.persisted) {
            setPage(Page.PLAYER_AMOUNT_FORM);
            navigate("/");
        }
    }, [location?.pathname, location?.state?.persisted, navigate]);

    return (
        <CopyProvider>
            <Header onSetPage={setPage} page={page} />
            <Routes>
                <Route
                    path="/"
                    element={
                        <TournamentForm
                            availableChampions={availableChampions}
                            onSetAvailableChampions={setAvailableChampions}
                            onSetPage={setPage}
                            onSetTournamentSettings={setTournamentSettings}
                            page={page}
                            tournamentSettings={tournamentSettings}
                        />
                    }
                />
                <Route
                    path="/tournament"
                    element={
                        <Tournament
                            onSetPage={setPage}
                            page={page}
                            tournamentSettings={tournamentSettings}
                        />
                    }
                />
                <Route path="/privacy" element={<PrivacyPolicy />} />
            </Routes>
            <Footer />
        </CopyProvider>
    );
};

export default App;
