import { Match, Player } from "../../types";
import { Fragment } from "react";
import "../styles/match-card.scss";

interface Props {
    canTie?: boolean;
    interactable?: boolean;
    match: Match;
    onSetWinner: (match: Match, player: Player, canTie: boolean) => void;
}

const MatchCard = ({
    canTie = true,
    interactable = true,
    match,
    onSetWinner,
}: Props) => {
    const getWinnerText = () => {
        if (match.winner.length > 1) {
            return "tie";
        }
        if (match.winner.length > 0) {
            return match.winner[0].name;
        }
        return "vs";
    };

    const getWinnerClassSuffix = () => {
        if (match.winner.length > 1) {
            return " tournament__match-card__winner--undecided";
        }
        if (match.winner.length > 0) {
            return " tournament__match-card__winner--decided";
        }
        return "";
    };

    return (
        <div
            className={
                "tournament__match-card " +
                (!interactable ? "tournament__match-card--disbaled" : "")
            }
        >
            {match.players.map((p, index) => (
                <Fragment key={index}>
                    {index > 0 && (
                        <span
                            className={
                                "tournament__match-card__horizontal-line" +
                                (match.winner.includes(p)
                                    ? " tournament__match-card__horizontal-line--winner"
                                    : "")
                            }
                        />
                    )}
                    <button
                        className={
                            "tournament__match-card__player" +
                            (match.winner.includes(p)
                                ? " tournament__match-card__player--winner"
                                : "")
                        }
                        disabled={!interactable}
                        onClick={() => onSetWinner(match, p, canTie)}
                    >
                        <h4>{p.name}</h4>
                    </button>
                    {index < match.players.length - 1 && (
                        <Fragment>
                            <span
                                className={
                                    "tournament__match-card__horizontal-line" +
                                    (match.winner.includes(p)
                                        ? " tournament__match-card__horizontal-line--winner"
                                        : "")
                                }
                            />
                            <p
                                className={
                                    "tournament__match-card__winner" +
                                    getWinnerClassSuffix()
                                }
                            >
                                {getWinnerText()}
                            </p>
                        </Fragment>
                    )}
                </Fragment>
            ))}
        </div>
    );
};

export default MatchCard;
