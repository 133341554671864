import {
    createContext,
    ReactNode,
    useCallback,
    useContext,
    useEffect,
    useState,
} from "react";

interface CopyContextType {
    copy: CopyData;
    loading: boolean;
    s: (path: string) => string;
}

interface CopyData {
    [key: string]: string | CopyData;
}

interface Props {
    children: ReactNode;
}

const defaultCopyContext: CopyContextType = {
    copy: {},
    loading: true,
    s: (path: string) => path,
};
const CopyContext = createContext(defaultCopyContext);

const CopyProvider = ({ children }: Props) => {
    const [copy, setCopy] = useState<CopyData>(defaultCopyContext.copy);
    const [loading, setLoading] = useState(defaultCopyContext.loading);

    const fetchCopy = useCallback(async () => {
        try {
            const module = await import(`../resources/copy.json`);
            setCopy(module.default as CopyData);
        } catch (e) {
            console.log("Failed to load copy: ", e);
        }
    }, []);

    const s = (path: string): string => {
        if (loading) {
            return path;
        }
        const keys = path.split(".");
        let result: any = copy;
        for (let i = 0; i < keys.length; i++) {
            result = result[keys[i]];
            if (!result) break;
        }

        return result || path;
    };

    useEffect(() => {
        fetchCopy().then(() => setLoading(false));
    }, [fetchCopy]);

    return (
        <CopyContext.Provider value={{ copy, loading, s }}>
            {children}
        </CopyContext.Provider>
    );
};

const useCopy = () => {
    return useContext(CopyContext);
};

export { CopyProvider, useCopy };
