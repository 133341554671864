export interface Champion {
    id: number;
    name: string;
}

export interface Match {
    id: number;
    players: Player[];
    winner: Player[];
}

export const enum Page {
    PLAYER_AMOUNT_FORM,
    PLAYER_NAMES_FORM,
    PLAY_IN_TEAMS_FORM,
    TEAM_AMOUNT_FORM,
    TEAM_FILL_FORM,
    TOURNAMENT_TYPE_FORM,
    OVERVIEW_FORM,
    TOURNAMENT,
}

export interface Player {
    id: number;
    losses: number;
    name: string;
    score: number;
    ties: number;
    wins: number;
}

export interface Team extends Champion {
    champions: Champion[];
}

export const enum TeamCardInteraction {
    CHANGE_NAME,
    ADD_CHAMPIONS,
    REMOVE_CHAMPIONS,
}

export interface TournamentSettings {
    champions: Champion[];
    playInTeams: boolean;
    teams: Team[];
    tournamentType: TournamentType;
}

export const enum TournamentType {
    SINGLE_ELIMINATION,
    ROUND_ROBIN,
}

export interface ITournamentType {
    rounds: Match[][];
    getRound: (roundPlayers: Player[]) => Match[];
}
