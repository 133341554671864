import "../styles/input-number.scss";

interface Props {
    max?: number;
    min?: number;
    onValueChange?: (value: number) => void;
    value?: number;
}

const InputNumber = ({
    onValueChange = (value: number) => {},
    max = 100,
    min = 0,
    value = 50,
}: Props) => {
    const onDecrement = () => {
        if (value > min) onValueChange(value - 1);
    };

    const onIncrement = () => {
        if (value < max) onValueChange(value + 1);
    };

    return (
        <div className="input-number__container">
            <button
                className="input-number__counter-button"
                disabled={!(value > min)}
                onClick={onDecrement}
            >
                -
            </button>
            <div className="input-number__number">{value}</div>
            <button
                className="input-number__counter-button"
                disabled={!(value < max)}
                onClick={onIncrement}
            >
                +
            </button>
        </div>
    );
};

export default InputNumber;
