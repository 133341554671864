import { createRef, RefObject, useEffect } from "react";
import { Champion } from "../../types";
import "../styles/spin-wheel-canvas.scss";

interface Props {
    champions: Champion[];
    diameter?: number;
}

const drawFret = (
    arc: number,
    ctx: CanvasRenderingContext2D,
    centerX: number,
    centerY: number,
    color: string,
    insideRadius: number,
    outsideRadius: number,
    last: boolean,
    startAngle: number,
    endAngle: number,
    text: string,
): void => {
    ctx.fillStyle = color;
    ctx.beginPath();
    ctx.moveTo(centerX, centerY);
    ctx.arc(centerX, centerY, outsideRadius, startAngle, endAngle, false);
    ctx.arc(centerX, centerY, insideRadius, endAngle, startAngle, true);
    ctx.fill();
    ctx.save();

    drawFretEdge(
        ctx,
        centerX,
        centerY,
        insideRadius,
        outsideRadius,
        startAngle,
    );
    if (last) {
        drawFretEdge(
            ctx,
            centerX,
            centerY,
            insideRadius,
            outsideRadius,
            endAngle,
        );
    }
    ctx.lineWidth = 6;
    ctx.beginPath();
    ctx.arc(
        centerX,
        centerY,
        outsideRadius - ctx.lineWidth / 2,
        0,
        2 * Math.PI,
    );
    ctx.closePath();
    ctx.stroke();

    ctx.beginPath();
    ctx.arc(
        centerX,
        centerY,
        insideRadius + ctx.lineWidth / 2 - 1,
        0,
        2 * Math.PI,
    );
    ctx.closePath();
    ctx.stroke();

    let rotationAngle = startAngle + arc / 2;

    ctx.translate(
        centerX + Math.cos(rotationAngle),
        centerY + Math.sin(rotationAngle),
    );
    ctx.rotate(rotationAngle);
    ctx.font = "32px Bangers";
    ctx.fillStyle = getComputedStyle(document.documentElement).getPropertyValue(
        "--text-color",
    );
    ctx.shadowOffsetX = 1;
    ctx.shadowOffsetY = 1;
    ctx.shadowBlur = 0;
    ctx.shadowColor = "black";
    const textWidth = ctx.measureText(text).width;
    const fontSize =
        32 * (textWidth > centerX - 100 ? (centerX - 100) / textWidth : 1);
    ctx.font = `${fontSize}px Bangers`;
    ctx.fillText(
        text,
        centerX / 2 - ctx.measureText(text).width / 2 + 15,
        fontSize / 2,
    );
    ctx.restore();
};

const drawFretEdge = (
    ctx: CanvasRenderingContext2D,
    centerX: number,
    centerY: number,
    insideRadius: number,
    outsideRadius: number,
    angle: number,
): void => {
    ctx.beginPath();
    ctx.moveTo(
        centerX + (insideRadius + 1) * Math.cos(angle),
        centerY + (insideRadius + 1) * Math.sin(angle),
    );
    ctx.lineTo(
        centerX + (outsideRadius - 1) * Math.cos(angle),
        centerY + (outsideRadius - 1) * Math.sin(angle),
    );
    ctx.closePath();
    ctx.stroke();
};

const drawWheel = (
    canvasRef: RefObject<HTMLCanvasElement>,
    champions: Champion[],
): void => {
    const canvas = canvasRef.current;

    if (canvas?.getContext("2d")) {
        const ctx = canvas?.getContext("2d") as CanvasRenderingContext2D;
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.strokeStyle = getComputedStyle(
            document.documentElement,
        ).getPropertyValue("--edge-color");
        ctx.lineWidth = 2;

        let startAngle = 0;
        const outsideRadius = canvas.width / 2 - 10;
        const insideRadius = (outsideRadius * 20) / 100;
        const arc = (2 * Math.PI) / champions.length;
        const centerX = canvas?.width / 2;
        const centerY = canvas?.height / 2;

        if (!(champions.length > 0)) {
            const color = getComputedStyle(
                document.documentElement,
            ).getPropertyValue(`--fret3-color`);
            drawFret(
                arc,
                ctx,
                centerX,
                centerY,
                color,
                insideRadius,
                outsideRadius,
                true,
                startAngle,
                2 * Math.PI,
                "",
            );
            return;
        }

        champions.forEach((c, index) => {
            const endAngle = startAngle + arc;
            const color = getComputedStyle(
                document.documentElement,
            ).getPropertyValue(
                `--fret${(index % (champions.length % 2 ? 3 : 2)) + 1}-color`,
            );
            drawFret(
                arc,
                ctx,
                centerX,
                centerY,
                color,
                insideRadius,
                outsideRadius,
                index === champions.length - 1,
                startAngle,
                endAngle,
                c.name,
            );
            startAngle = endAngle;
        });
    }
};

const SpinWheelCanvas = ({ champions, diameter = 500 }: Props) => {
    const canvasRef = createRef<HTMLCanvasElement>();

    useEffect(() => {
        drawWheel(canvasRef, champions);
    }, [canvasRef, champions, diameter]);

    return <canvas height={diameter} ref={canvasRef} width={diameter} />;
};

export default SpinWheelCanvas;
