import React from "react";
import { useCopy } from "../../../contexts/copy-provider";
import "../styles/main.scss";

const PrivacyPolicy = () => {
    const { s } = useCopy();
    return (
        <>
            <h2>{s("privacy-policy.title")}</h2>
            <p>{s("privacy-policy.copy1")}</p>
            <p className="footer__dialog-p">{s("privacy-policy.copy2")}</p>
            <p className="footer__dialog-p">{s("privacy-policy.copy3")}</p>
            <p className="footer__dialog-p">{s("privacy-policy.copy4")}</p>
            <p className="footer__dialog-p">{s("privacy-policy.copy5")}</p>
        </>
    );
};

export default PrivacyPolicy;
