import { Champion, Team, TeamCardInteraction } from "../../types";
import TeamCard from "../../team-card";
import { useState } from "react";
import AddPlayersDialog from "./add-players-dialog";

interface Props {
    availableChampions: Champion[];
    onAddToTeam: (champions: Champion[], team: Team) => void;
    onChangeTeamName: (name: string, team: Team) => void;
    onRemoveFromTeam: (champion: Champion, team: Team) => void;
    teams: Team[];
}

const ManualTeamForm = ({
    availableChampions,
    onAddToTeam,
    onChangeTeamName,
    onRemoveFromTeam,
    teams,
}: Props) => {
    const [addPlayerDialogOpen, setAddPlayerDialogOpen] = useState(false);
    const [selectedTeam, setSelectedTeam] = useState<Team | null>(null);
    const [emptyTeams, setEmptyTeams] = useState(
        teams.filter((t) => !(t.champions.length > 0)),
    );

    const handleOpenAddPlayerDialog = (team: Team | null) => {
        setEmptyTeams(teams.filter((t) => !(t.champions.length > 0)));
        setSelectedTeam(team);
        setAddPlayerDialogOpen(!!team);
    };

    const handleRemoveFromTeam = (champion: Champion, team: Team) => {
        onRemoveFromTeam(champion, team);
        setEmptyTeams(teams.filter((t) => !(t.champions.length > 0)));
    };

    const interactions = [
        TeamCardInteraction.CHANGE_NAME,
        TeamCardInteraction.REMOVE_CHAMPIONS,
    ];

    return (
        <>
            {addPlayerDialogOpen && selectedTeam && (
                <AddPlayersDialog
                    availableChampions={availableChampions}
                    emptyTeams={emptyTeams}
                    onAddToTeam={onAddToTeam}
                    onOpenAddPlayerDialog={handleOpenAddPlayerDialog}
                    onSetEmptyTeams={setEmptyTeams}
                    selectedTeam={selectedTeam}
                />
            )}
            {teams.map((t, index) => (
                <TeamCard
                    interactions={
                        availableChampions.length > emptyTeams.length ||
                        emptyTeams.includes(t)
                            ? [
                                  ...interactions,
                                  TeamCardInteraction.ADD_CHAMPIONS,
                              ]
                            : interactions
                    }
                    key={index}
                    onChangeTeamName={onChangeTeamName}
                    onOpenAddPlayerDialog={handleOpenAddPlayerDialog}
                    onRemoveFromTeam={handleRemoveFromTeam}
                    team={t}
                />
            ))}
        </>
    );
};

export default ManualTeamForm;
