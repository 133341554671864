import {
    Match,
    Page,
    Player,
    TournamentSettings,
    TournamentType,
} from "../types";
import { useEffect, useRef, useState } from "react";
import RoundRobin from "./components/round-robin";
import SingleElimination from "./components/single-elimination";
import "./styles/main.scss";
import TeamCard from "../team-card";
import Dialog from "../ui-components/components/dialog";
import { useLocation, useNavigate } from "react-router-dom";
import sessionsApi from "../../services/sessions-api";
import { Adsense } from "@ctrl/react-adsense";

const shuffle = <T extends unknown>(array: T[]): T[] => {
    let currentIndex = array.length,
        randomIndex;

    while (currentIndex > 0) {
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        [array[currentIndex], array[randomIndex]] = [
            array[randomIndex],
            array[currentIndex],
        ];
    }

    return array;
};

const getPlayers = (tournamentSettings: TournamentSettings): Player[] => {
    if (tournamentSettings.playInTeams) {
        return tournamentSettings.teams.map((t) => {
            return {
                id: t.id,
                losses: 0,
                name: t.name,
                score: 0,
                ties: 0,
                wins: 0,
            };
        });
    }
    return tournamentSettings.champions.map((c) => {
        return {
            id: c.id,
            losses: 0,
            name: c.name,
            score: 0,
            ties: 0,
            wins: 0,
        };
    });
};

interface Props {
    onSetPage: (page: Page) => void;
    page: Page;
    tournamentSettings: TournamentSettings;
}

const Tournament = ({ onSetPage, page, tournamentSettings }: Props) => {
    const [players, setPlayers] = useState(
        shuffle(getPlayers(tournamentSettings)),
    );
    const [tournamentWinner, setTournamentWinner] = useState(Array<Player>(0));
    const [matches, setMatches] = useState(Array<Match>(0));
    const [showWinnerDialog, setShowWinnerDialog] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (page !== Page.TOURNAMENT) {
            onSetPage(Page.TOURNAMENT);
        }

        if (location?.state?.tournamentSettings) {
            tournamentSettings = location.state.tournamentSettings;
        }

        if (!(tournamentWinner.length > 0)) {
            return;
        }
        setShowWinnerDialog(true);
        if (winnerRef.current) {
            winnerRef.current?.scrollIntoView({ behavior: "smooth" });
        }
    }, [matches, onSetPage, page, tournamentWinner.length]);

    const handleResetPlayerStats = (players: Player[]) => {
        setPlayers(
            players.map((p) => {
                return {
                    id: p.id,
                    losses: 0,
                    name: p.name,
                    score: 0,
                    ties: 0,
                    wins: 0,
                };
            }),
        );
    };

    const winnerRef = useRef<null | HTMLDivElement>(null);

    const handleSetMatches = (matches: Match[]) => {
        const state = { ...location.state, matches: matches };
        navigate("/tournament", { state, replace: true });
        setMatches(matches);
    };

    const handleSetMatchWinner = (
        match: Match,
        winner: Player,
        canTie: boolean,
    ) => {
        if (!match.winner.includes(winner)) {
            if (!canTie) {
                match.winner = [];
            }
            match.winner.push(winner);
        } else {
            match.winner = match.winner.filter((p) => p.id !== winner.id);
        }
        location.state.matches = matches;
        handleSetMatches(matches.map((m) => (m.id === match.id ? match : m)));
    };

    const handleSetTournamentWinner = (winner: Player[]) => {
        setTournamentWinner(winner);

        if (winner.length > 0) {
            const req = {
                sessionId: location.state.sessionId,
                players: tournamentSettings.champions.length,
                teams:
                    (tournamentSettings.playInTeams &&
                        tournamentSettings.teams.length) ||
                    0,
                type: tournamentSettings.tournamentType,
            };

            sessionsApi.put("/tournament_sessions", req).catch(() => {});
        }
    };

    return (
        <div className="tournament">
            <h1 ref={winnerRef}>Beer Pong Tournament</h1>
            {tournamentSettings.tournamentType ===
                TournamentType.ROUND_ROBIN && (
                <RoundRobin
                    matches={matches}
                    onResetPlayerStats={handleResetPlayerStats}
                    onSetMatches={handleSetMatches}
                    onSetMatchWinner={handleSetMatchWinner}
                    onSetTournamentWinner={handleSetTournamentWinner}
                    playerCount={getPlayers(tournamentSettings).length}
                    players={players}
                />
            )}
            {tournamentSettings.tournamentType ===
                TournamentType.SINGLE_ELIMINATION && (
                <SingleElimination
                    matches={matches}
                    onSetMatches={handleSetMatches}
                    onSetMatchWinner={handleSetMatchWinner}
                    onSetTournamentWinner={handleSetTournamentWinner}
                    playerCount={getPlayers(tournamentSettings).length}
                    players={players}
                />
            )}
            {tournamentWinner.length > 0 && (
                <>
                    <h2>Winner{tournamentWinner.length > 1 ? "s" : ""}:</h2>
                    {tournamentWinner.map((w) => (
                        <h3 key={w.id}>{w.name}</h3>
                    ))}
                </>
            )}
            {showWinnerDialog && (
                <Dialog onClose={() => setShowWinnerDialog(false)}>
                    <h2>Winner{tournamentWinner.length > 1 ? "s" : ""}:</h2>
                    {tournamentWinner.map((w) => (
                        <h3 key={w.id}>{w.name}</h3>
                    ))}
                </Dialog>
            )}
            {tournamentSettings.playInTeams &&
                tournamentSettings.teams.map((t, index) => (
                    <TeamCard key={index} team={t} />
                ))}
            <Adsense client="ca-pub-8619869984033703" slot="1482847604" />
        </div>
    );
};

export default Tournament;
