import "../styles/main.scss";
import { useCopy } from "../../../contexts/copy-provider";

interface Props {
    onClickNext: (value: number) => void;
    setPlayInTeams: (value: boolean) => void;
}

const PlayInTeamsForm = ({ onClickNext, setPlayInTeams }: Props) => {
    const { s } = useCopy();

    return (
        <div>
            <h2>{s("beer-pong.pages.play-in-teams-form.title")}?</h2>
            <div className="tournament-form__form-body">
                <button
                    className="tournament-form__group-button"
                    onClick={() => {
                        setPlayInTeams(false);
                        onClickNext(3);
                    }}
                >
                    {s("general.no")}
                </button>
                <button
                    className="tournament-form__group-button"
                    onClick={() => {
                        setPlayInTeams(true);
                        onClickNext(1);
                    }}
                >
                    {s("general.yes")}
                </button>
            </div>
        </div>
    );
};

export default PlayInTeamsForm;
