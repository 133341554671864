import InputNumber from "../../ui-components/components/input-number";
import { useCopy } from "../../../contexts/copy-provider";

interface Props {
    amount: number;
    max?: number;
    min?: number;
    onValueChange?: (amount: number) => void;
}

const PlayerAmountForm = ({
    max = 16,
    min = 2,
    onValueChange = () => {},
    amount,
}: Props) => {
    const { s } = useCopy();

    return (
        <div>
            <h2>{s("beer-pong.pages.player-amount-form.title")}</h2>
            <div className="tournament-form__form-body">
                <InputNumber
                    onValueChange={onValueChange}
                    max={max}
                    min={min}
                    value={amount}
                />
            </div>
        </div>
    );
};

export default PlayerAmountForm;
