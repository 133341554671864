import styles from "../RoundRobin/RoundRobin.module.css";
import { Match, Player, TournamentType } from "../../types";
import { Fragment, useEffect, useState } from "react";
import MatchCard from "./match-card";
import { useLocation, useNavigate } from "react-router-dom";

interface Props {
    matches: Match[];
    onResetPlayerStats: (players: Player[]) => void;
    onSetMatches: (matches: Match[]) => void;
    onSetMatchWinner: (match: Match, winner: Player, canTie: boolean) => void;
    onSetTournamentWinner: (winner: Player[]) => void;
    playerCount: number;
    players: Player[];
}

const RoundRobin = ({
    matches,
    onResetPlayerStats,
    onSetMatches,
    onSetMatchWinner,
    onSetTournamentWinner,
    playerCount,
    players,
}: Props) => {
    const [rounds, setRounds] = useState<Match[][]>([]);
    const location = useLocation();
    const navigate = useNavigate();

    const calculateScores = () => {
        const finalPlayers = players;
        matches.forEach((m) => {
            let hasMultiple = m.winner.length > 1;
            m.players.forEach((p) => {
                const found = finalPlayers.find((f) => f.id === p.id);
                const isWinner = m.winner.find((w) => w.id === p.id);
                if (found) {
                    if (isWinner) {
                        found.losses += !isWinner ? 1 : 0;
                        found.score += hasMultiple ? 1 : 3;
                        found.ties += hasMultiple ? 1 : 0;
                        found.wins += isWinner ? 1 : 0;
                    }
                }
            });
        });

        finalPlayers.sort((a, b) => b.score - a.score);

        const highscore = finalPlayers[0].score;
        const winner = [finalPlayers[0]];

        for (let i = 1; i < finalPlayers.length; i++) {
            const p = finalPlayers[i];
            if (p.score < highscore) {
                break;
            }
            winner.push(p);
        }

        onSetTournamentWinner(winner);
    };

    const checkForTournamentWinner = () => {
        onResetPlayerStats(players);
        for (let i = 0; i < matches.length; i++) {
            if (!(matches[i].winner.length > 0)) {
                onSetTournamentWinner([]);
                return;
            }
        }
        calculateScores();
    };

    try {
        useEffect(() => {
            const generateMatches = (
                odd: boolean,
                roundsAmount: number,
            ): void => {
                if (odd)
                    players.push({
                        id: -1,
                        losses: -1,
                        name: "",
                        score: -1,
                        ties: -1,
                        wins: -1,
                    });

                const halfIndex = players.length / 2;
                const playerIndices = players.map((team, i) => i).slice(1);

                for (let round = 0; round < roundsAmount; round++) {
                    const newPlayerIndices = [0].concat(playerIndices);

                    const firstHalf = newPlayerIndices.slice(0, halfIndex);
                    const secondHalf = newPlayerIndices
                        .slice(halfIndex, players.length)
                        .reverse();

                    for (let i = 0; i < firstHalf.length; i++) {
                        const matchPlayers = [
                            players[firstHalf[i]],
                            players[secondHalf[i]],
                        ];

                        if (matchPlayers.some((e) => e.id === -1)) {
                            continue;
                        }
                        matches.push({
                            id: matches.length,
                            players: matchPlayers,
                            winner: [],
                        });
                    }

                    playerIndices.push(playerIndices.shift() || -1);
                }

                onSetMatches(matches);
            };

            const odd = playerCount % 2 !== 0;
            const roundsAmount = odd ? playerCount : playerCount - 1;
            const roundLength = odd ? (playerCount - 1) / 2 : playerCount / 2;

            if (!(matches.length > 0)) {
                if (!(location.state?.matches?.length > 0)) {
                    generateMatches(odd, roundsAmount);
                } else {
                    onSetMatches(location.state.matches);
                }
            }

            const rounds: Match[][] = [];
            for (let i = 0; i < roundsAmount * roundLength; i += roundLength) {
                rounds.push([...matches.slice(i, i + roundLength)]);
            }
            setRounds(rounds);
        }, [
            matches,
            playerCount,
            players,
            onSetMatches,
            location.state.matches,
            navigate,
        ]);
    } catch (e) {}

    return (
        <div className="tournament__matches">
            {rounds.map((r, index) => (
                <div className="tournament__matches__round" key={index}>
                    <h4>Round {index + 1}</h4>
                    {r.map((m, index) => (
                        <MatchCard
                            key={index}
                            match={m}
                            onSetWinner={(match: Match, winner: Player) => {
                                onSetMatchWinner(match, winner, true);
                                checkForTournamentWinner();
                            }}
                        />
                    ))}
                </div>
            ))}
        </div>
    );
};

export default RoundRobin;
